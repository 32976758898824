import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-lancaster-california.png'
import image0 from "../../images/cities/scale-model-of-los-angeles-county-airshow-in-lancaster-california.png"
import image1 from "../../images/cities/scale-model-of-elyze-clifford-interpretive-center-in-lancaster-california.png"
import image2 from "../../images/cities/scale-model-of-prime-desert-woodland-preserve-in-lancaster-california.png"
import image3 from "../../images/cities/scale-model-of-marie-kerr-park-in-lancaster-california.png"
import image4 from "../../images/cities/scale-model-of-apollo-community-regional-park-in-lancaster-california.png"
import image5 from "../../images/cities/scale-model-of-george-lane-park-in-lancaster-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Lancaster'
            state='California'
            image={image}
            lat='34.6936'
            lon='-118.1753'
            attractions={ [{"name": "Los Angeles County Airshow", "vicinity": "4555 W Ave G, Lancaster", "types": ["point_of_interest", "establishment"], "lat": 34.738632, "lng": -118.2152054}, {"name": "ELYZE CLIFFORD INTERPRETIVE CENTER", "vicinity": "43201 35th St W, Lancaster", "types": ["park", "point_of_interest", "establishment"], "lat": 34.6676813, "lng": -118.19370979999997}, {"name": "Prime Desert Woodland Preserve", "vicinity": "43201 35th St W, Lancaster", "types": ["park", "point_of_interest", "establishment"], "lat": 34.6676449, "lng": -118.19296930000002}, {"name": "Marie Kerr Park", "vicinity": "39700 30th St W, Palmdale", "types": ["park", "point_of_interest", "establishment"], "lat": 34.6036659, "lng": -118.18204100000003}, {"name": "Apollo Community Regional Park", "vicinity": "4555 W Ave G, Lancaster", "types": ["park", "point_of_interest", "establishment"], "lat": 34.7409127, "lng": -118.20320889999999}, {"name": "George Lane Park", "vicinity": "5520 W Ave L8, Quartz Hill", "types": ["park", "point_of_interest", "establishment"], "lat": 34.6516351, "lng": -118.2287235}] }
            attractionImages={ {"Los Angeles County Airshow":image0,"ELYZE CLIFFORD INTERPRETIVE CENTER":image1,"Prime Desert Woodland Preserve":image2,"Marie Kerr Park":image3,"Apollo Community Regional Park":image4,"George Lane Park":image5,} }
       />);
  }
}